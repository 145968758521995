import { apiRequest } from './helpers';
import { GeneralFilter, ExpertsData, ExpertDetails, ExpertScheduler, ExpertSchedulerSession, SignupSessionData } from '../types/experts';

export const getExpertsList = (locale: string, filter?: GeneralFilter): Promise<ExpertsData> => apiRequest({
    url: '/home/coachsearch1',
    method: 'post',
    data: { ...filter },
    locale
});

export const getExpertById = (id: string, locale: string): Promise<ExpertDetails> => apiRequest({
    url: '/home/coachdetails',
    method: 'post',
    data: { id },
    locale
});

export const getSchedulerByExpertId = (id: string, locale: string): Promise<ExpertScheduler> => apiRequest({
    url: '/home/sessionsignupdata',
    method: 'post',
    data: { id },
    locale
});

export const getSchedulerSession = (data: SignupSessionData, locale: string, token: string): Promise<ExpertSchedulerSession> => apiRequest({
    url: '/home/sessionsignupsubmit',
    method: 'post',
    data,
    locale,
    token
});
