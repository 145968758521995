import { AdditionalFilter, Filter, GeneralFilter } from '../types/experts';
import { DEFAULT_PAGE } from '../constants/common';

export const getDefaultFilter = (pageSize?: number): Filter => {
    // const themesTagIds = searchData?.themesGroups?.reduce<number[]>((result, { tags }) => {
    //     const t = tags?.map(({ id }) => id) || [];
    //
    //     return t ? [
    //         ...result,
    //         ...t
    //     ] : result;
    // }, []);

    return {
        themesTagIds: [],
        priceFrom: null,
        priceTo: null,
        durationFrom: null,
        durationTo: null,
        pageSize,
        page: DEFAULT_PAGE
    } as Filter;
};

type FilterProps = {
    pageSize?: number,
    searchParams?: { [key: string]: string | string[] | undefined }
}

export const getFilter = ({ searchParams, pageSize }: FilterProps): Filter => {
    const filter = getDefaultFilter(pageSize);

    if (searchParams) {
        const { themesTagIds, priceFrom, priceTo, durationFrom, durationTo, page, pageSize } = searchParams;

        if (themesTagIds) {
            if (Array.isArray(themesTagIds) && themesTagIds?.length > 0) {
                filter.themesTagIds = themesTagIds.map((id) => +id);
            }

            if (typeof themesTagIds === 'string') {
                filter.themesTagIds = [+themesTagIds];
            }
        }

        if (priceFrom) {
            filter.priceFrom = +priceFrom;
        }

        if (priceTo) {
            filter.priceTo = +priceTo;
        }

        if (durationFrom) {
            filter.durationFrom = +durationFrom;
        }

        if (durationTo) {
            filter.durationTo = +durationTo;
        }

        if (page) {
            filter.page = +page;
        }

        if (pageSize) {
            filter.pageSize = +pageSize;
        }
    }

    return filter;
};

export const getObjectByFilter = (searchParams?: any): Filter => {
    const filter: Filter = {};

    let tags = searchParams?.getAll('themesTagIds');
    let languages = searchParams?.getAll('userLanguages');

    if (tags && tags.length > 0) {
        filter.themesTagIds = tags.map((id: string) => Number(id));
    }

    if (languages && languages.length > 0) {
        filter.userLanguages = languages.map((code: string) => code);
    }

    if (searchParams?.has('priceFrom')) {
        filter.priceFrom = Number(searchParams.get('priceFrom'));
    }

    if (searchParams?.has('priceTo')) {
        filter.priceTo = Number(searchParams.get('priceTo'));
    }

    if (searchParams?.has('durationFrom')) {
        filter.durationFrom = Number(searchParams.get('durationFrom'));
    }

    if (searchParams?.has('durationTo')) {
        filter.durationTo = Number(searchParams.get('durationTo'));
    }

    if (searchParams?.has('page')) {
        filter.page = Number(searchParams.get('page'));
    }

    return filter;
};

export const getObjectByAdditionalFilter = (searchParams?: any): AdditionalFilter => {
    const additionalFilter: AdditionalFilter = {};

    if (searchParams?.has('text')) {
        additionalFilter.text = searchParams.get('text');
    }

    if (searchParams?.has('coachSort')) {
        additionalFilter.coachSort = searchParams.get('coachSort');
    }

    return additionalFilter;
};

export const getSearchParamsString = (filter: GeneralFilter): string => {
    const searchParams = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
        if (value) {
            if (Array.isArray(value)) {
                value.forEach((val) => {
                    searchParams.append(key, `${val}`);
                });
            } else {
                searchParams.set(key, `${value}`);
            }
        }
    })

    return searchParams.toString();
};
